import React, { useState, useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import { CgArrowLeftO } from "react-icons/cg";
import { CgMoreO } from "react-icons/cg";
import LazyLoad from "react-lazyload";

const ItemCard = ({ item, index }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [timeDiff, setTimeDiff] = useState("");

  useEffect(() => {
    // Calculate the time difference
    const pubDate = new Date(item.pubDate);
    const currentTime = new Date();
    const diff = currentTime - pubDate;

    // Convert milliseconds to seconds
    const seconds = Math.floor(diff / 1000);

    // Convert seconds to appropriate unit
    if (seconds < 15 * 60) {
      setTimeDiff("Now");
    } else if (seconds < 3600) {
      const mins = Math.floor(seconds / 60);
      setTimeDiff(`${mins} min${mins === 1 ? "" : "s"} ago`);
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      setTimeDiff(`${hours} hour${hours === 1 ? "" : "s"} ago`);
    } else {
      const days = Math.floor(seconds / 86400);
      setTimeDiff(`${days} day${days === 1 ? "" : "s"} ago`);
    }
  }, [item.pubDate]);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  // Determine the source based on the item's link
  const urlSources = {
    gamespot: "GameSpot",
    "ign.com": "IGN",
    vg247: "VG247",
    "polygon.com": "Polygon",
    kotaku: "Kotaku",
    eurogamer: "Eurogamer",
    gamesradar: "GamesRadar+",
    rockpapershotgun: "Rock Paper Shotgun",
    destructoid: "Destructoid",
    pcgamer: "PC Gamer",
  };

  let sourceKey = Object.keys(urlSources).find((key) =>
    item.link.includes(key)
  );
  let source = sourceKey ? urlSources[sourceKey] : "";



  function stripHtml(html) {
    if (!html) return ""; // Return an empty string if the input is null or undefined
  
    // Remove HTML tags
    const strippedHtml = html.replace(/<[^>]+>/g, "");
    // Decode HTML entities
    const decodedHtml = new DOMParser().parseFromString(
      strippedHtml,
      "text/html"
    ).body.textContent;
    return decodedHtml;
  }
  



  function truncateTitle(title) {
    const words = title.split(" ");
    if (words.length > 26) {
      return words.slice(0, 26).join(" ") + "...";
    } else {
      return title;
    }
  }

  function truncateSummary(summary) {
    const words = summary.split(" ");
    if (words.length > 34) {
      return words.slice(0, 34).join(" ") + "...";
    } else {
      return summary;
    }
  }

  function getImageUrl() {
    // Check if item.enclosures is defined and has at least one element
    if (item.enclosures && item.enclosures.length > 0) {
      const enclosure = item.enclosures[0];
      const url = enclosure.url;

      // Check if the URL ends with common image file extensions
      if (
        url.endsWith(".jpg") ||
        url.endsWith(".jpeg") ||
        url.endsWith(".png") ||
        url.endsWith(".gif")
      ) {
        return url; // Return the image URL
      }
    }

    // If item.description is null or undefined, provide a default image
    if (!item.description) {
      return "./images/no-image.png"; // Provide the path to your default image
    }

    // If item.enclosures is not available, empty, or doesn't contain an image URL, check for image in item.description
    const regex = /<img.*?src="(.*?)".*?>/; // Regular expression to extract image URL
    const match = item.description.match(regex); // Find the first image tag in the description

    if (match && match[1]) {
      return match[1]; // Return the URL from the first match
    } else {
      return "./images/no-image.png"; // Provide the path to your default image
    }
  }

  return (
    <div key={index} className="col-md-6 col-lg-4 mb-4 mb-md-5 d-flex">
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <div
          className="card rounded-4 d-flex flex-column h-100"
          style={{ minHeight: "433px" }}
        >
          <LazyLoad height={200} offset={100}>
            {/* Wrap the image with LazyLoad */}
            <div className="card-img-container">
            <a href={item.link} target="_blank" rel="noopener noreferrer" className="image-link">
            <img src={getImageUrl()} className="card-img-top rounded-top-4" alt="" />
            </a>
            </div>
          </LazyLoad>

          <div className="card-body flex-grow-1">
            <div className="mb-2 d-flex justify-content-between">
              <span className="badge  mb-2 text-truncate">{source}</span>
              <span className="small">{timeDiff}</span>
            </div>
            <h3 className="mb-2">
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {truncateTitle(stripHtml(item.title))}
              </a>
            </h3>
            <p className="mb-5 author">{item.author}</p>
          </div>

          <div className="card-footer border-0 mb-3 text-center bg-transparent">
            <CgMoreO onClick={handleClick} className="icons" />
          </div>
        </div>

        <div
          className="card rounded-4 d-flex flex-column h-100"
          style={{ minHeight: "433px" }}
        >
          <LazyLoad height={200} offset={100}>
            {/* Wrap the image with LazyLoad */}
            <img
              src={getImageUrl()}
              className="card-img-top rounded-top-4"
              style={{ filter: "brightness(50%)", transform: "scaleX(-1)" }}
              alt=""
            />
          </LazyLoad>
          <div className="card-body flex-grow-1">
            <p className="mb-5 mb-md-4">
              {truncateSummary(stripHtml(item.description))}
            </p>
          </div>

          <div className="card-footer border-0 mb-3 text-center bg-transparent">
            <CgArrowLeftO onClick={handleClick} className="icons" />
          </div>
        </div>
      </ReactCardFlip>
    </div>
  );
};

export default ItemCard;
